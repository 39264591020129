<template>
  <div class="bg-grey job-listings">
    <b-container fluid class="pt-4">
      <b-row>
        <b-col id="bg-splash" class="col-md-12 py-5 mt-5 text-dark">
          <div class="col-11 mx-auto">
            <b-row class="bg-white rounded-lg shadow mx-md-5">
              <b-col class="col-md-12 job-details-card job-application-card py-3 mb-0">
                <div class="job-details-header">
                  <div>
                    <img :src="jobDetails.company_logo_path" :alt="jobDetails.company_long_name" width="50" height="50" />
                  </div>
                  <div>
                    <h6 class="grey-text">
                      {{ jobDetails.company_long_name }}
                    </h6>
                  </div>
                  <div class="grey-text">
                    <h6 class="d-flex">
                      <img src="@/assets/app-icons/clock.svg" width="16px" class="mr-2" />
                      {{ days }}d ago
                    </h6>
                  </div>
                </div>
                <!--End Job Details Header-->
                <div class="job-details-content">
                  <h5 class="job-title fpn-b">
                    {{ jobDetails.job_title }}
                  </h5>
                  <div>
                    <h6 class="heading">Salary (Annually)</h6>
                    <h6 class="head-text">
                      <div class="d-flex">
                        <div class="mr-2">{{ jobDetails.currency_code }}</div>
                        <div class="pr-2">
                          {{ formattedNumber(jobDetails.job_salary_range_from) }}
                        </div>

                        <div class="pr-2">-</div>
                        <div class="mr-2">{{ jobDetails.currency_code }}</div>
                        <div>{{ formattedNumber(jobDetails.job_salary_range_to) }}</div>
                      </div>
                    </h6>
                  </div>
                  <div>
                    <h6 class="heading">Job Type</h6>
                    <h6 class="head-text">{{ jobDetails.job_type }}</h6>
                  </div>
                  <div>
                    <h6 class="heading">Location</h6>
                    <!-- <h6
                      class="head-text"
                      v-for="(c, i) in jobDetails.city"
                      :key="i"
                    >
                      {{ c.city_name }},
                    </h6> -->

                    <div class="d-flex job-location">
                      <p v-for="(c, i) in jobDetails.city" :key="i">
                        {{ c.city_name }}
                      </p>
                    </div>
                  </div>
                  <!-- <div>
                    <button
                      v-b-modal="'modal' + index"
                      class="btn btn-success btn-match d-flex align-items-center"
                      :id="'modal' + index"
                    >
                      97% Match &nbsp;<img
                        src="@/assets/app-icons/question-mark.svg"
                      />
                    </button>
                  </div> -->
                </div>
                <!--End Job Details Content-->

                <div class="job-details-footer">
                  <div class="left">
                    <h6 class="left-text border-right">
                      <a @click="() => {
                        $router.push({
                          path: $router.push({
                            name: 'Job-Details',
                            params: { id: $route.params.job_id },
                          }),
                        });
                      }
                        ">
                        <span>JOB DETAILS</span>
                        <img src="@/assets/app-icons/details.svg" width="15px" class="ml-2" />
                      </a>
                    </h6>
                    <h6 class="left-text border-right">
                      <a @click="share" v-b-modal.share-modal>
                        <span> SHARE </span>
                        <img src="@/assets/app-icons/share.svg" width="15px" class="ml-2" />
                      </a>
                    </h6>
                    <h6 class="left-text pt-3">
                      <a @click="AddBookmark($route.params.job_id)">
                        <div :class="jobDetails.bookmark ? 'bookmark-yellow-block' : 'bookmark-black-block'">
                          <span> SHORTLIST </span>
                        </div>
                        <!-- <img
                            v-if="!jobDetails.bookmark"
                            src="@/assets/app-icons/bookmark.svg"
                            width="15px"
                            class="ml-2"
                          />
                          <img
                            v-if="jobDetails.bookmark"
                            src="@/assets/app-icons/yellow-bookmark.svg"
                            width="15px"
                            class="ml-2"
                          /> -->
                      </a>
                    </h6>
                  </div>
                </div>
                <!--End Job Details Footer-->
              </b-col>
            </b-row>
          </div>
        </b-col>

        <b-col class="col-12">
          <b-row class="bg-white rounded-lg shadow mx-md-5 px-4 mb-3 pt-3 mt-md-n4">
            <b-col class="col-12">
              <div class="flex-between border-bottom pb-2">
                <div class="d-flex align-items-center">
                  <img src="@/assets/app-icons/cv.svg" class="img-fluid pr-3" alt="cv-icon" />
                  <div>
                    <h5 class="fpn-b f-light">STEP 1</h5>
                    <h4 class="fpn-b mt-n2 mb-0">Add/upload your CV</h4>
                  </div>
                </div>
                <div class="bg-grey rounded-lg p-3 col-3">
                  <div class="d-flex align-items-center mb-2">
                    <div class="qst-icon mr-2">?</div>
                    <h5 class="fmp-b mb-0">TIPS</h5>
                  </div>

                  <p class="fs-12">File should be in pdf/doc format and no larger than 1mb in size</p>
                </div>
              </div>
              <div class="d-flex py-3 align-items-center">
                <div class="resume-box mt-3" style="width: 22%">
                  <div class="resume-doc" v-b-tooltip.hover :title="ResumeName">
                    <img src="@/assets/app-icons/pdf.svg" class="img-fluid mr-3" alt="pdf-icon" width="20px" />
                    <div @click="onClickUrl">
                      {{ ResumeName ? ResumeName : "Resume not uploaded" }}
                    </div>
                  </div>

                  <div @click="onDeleteResume" class="resume-action" style="cursor: pointer">
                    <img src="@/assets/app-icons/delete.svg" class="img-fluid" alt="delete-icon" width="20px" />
                  </div>
                </div>

                <div class="custom-upload pt-3 pl-3">
                  <label for="file-upload" class="btn btn-primary reupload-btn">
                    <img src="@/assets/app-icons/upload.svg" class="img-fluid mr-3" alt="upload-icon" width="20px" />

                    <!-- {{ cv && fileSelected ? "File Selected" : "UPLOAD" }} -->
                    {{ fileSelected ? "File Selected" : "RE-UPLOAD" }}
                  </label>
                  <input onclick="this.value = null" id="file-upload" type="file" @input="onFileChange" />
                </div>
              </div>
            </b-col>

            <b-col class="col-12">
              <div class="flex-between border-bottom py-3">
                <div class="d-flex align-items-center">
                  <img src="@/assets/app-icons/cv.svg" class="img-fluid pr-3" alt="cv-icon" width="60px" />
                  <div>
                    <h5 class="fpn-b f-light">STEP 2</h5>
                    <h4 class="fpn-b mt-n2 mb-0">Virtual Interview</h4>
                  </div>
                </div>
              </div>
              <div class="py-3">
                <p class="f-light fs-14">Answer some technical questions regarding your field</p>
                <div v-for="(data, index) in mandatoryVI" :key="index" class="border-bottom py-3">
                  <h5>Q{{ index + 1 }}. {{ data.question }}?</h5>
                  <div class="bg-white rounded-lg">
                    <b-form-textarea v-if="data.question_type == 'Text Based'"
                      v-model="ans.virtualInterview[index].answer" class="form-control" width-50 w-100 rows="5"
                      max-rows="10" placeholder="Enter Your Answer"> </b-form-textarea>
                    <div v-if="submit && String(ans.virtualInterview[index].answer).length == 0" class="error-msg">
                      <small>This field is required</small>
                    </div>
                  </div>

                  <b-form-group class="radio-square" v-if="data.option.length > 0">
                    <b-form-radio v-for="(o, i) in data.option" :key="i" :value="o.value"
                      v-model="ans.virtualInterview[index].answer" @change="onChangeVIOption(data, o)">
                      {{ o.text }}
                    </b-form-radio>
                  </b-form-group>
                  <div
                    v-if="submit && virtualInterview.length - 1 === index && String(ans.virtualInterview[index].answer).length == 0"
                    class="error-msg">
                    <small>This field is required</small>
                  </div>
                </div>
              </div>
            </b-col>

            <b-col class="col-12"
              v-if="Object.keys(virtualVideoInterview).length > 0 && virtualVideoInterview.constructor === Object && virtualVideoInterview.question != ''">
              <div class="flex-between border-bottom py-3">
                <div class="d-flex align-items-center">
                  <img src="@/assets/app-icons/video-online.svg" class="img-fluid pr-3" alt="cv-icon" width="70px" />
                  <div>
                    <h5 class="fpn-b f-light">STEP 3</h5>
                    <h4 class="fpn-b mt-n2 mb-0">Virtual Video Interview</h4>
                  </div>
                </div>
              </div>
              <div class="py-3" v-if="Object.keys(virtualVideoInterview).length > 0">
                <p class="f-light fs-14">Make a relevant and impactful impression by answering this video question in 30
                  seconds</p>
                <div>
                  <h5>Q1. {{ virtualVideoInterview.question }}?</h5>
                  <p class="f-light fs-14"><b>Tips:</b> {{ virtualVideoInterview.question_tips }}</p>
                </div>
                <!-- <div
                  class="ml-auto mr-auto w-50"
                  v-if="videos.length > 0 && videos[0].video_id"
                >
                
                  <iframe
                    :src="
                      `https://iframe.videodelivery.net/${
                        selectedVideo.selectedVideo
                          ? selectedVideo.selectedVideo
                          : videos.length > 0
                          ? videos[0].video_id
                          : null
                      }/thumbnails/thumbnail.jpg?time=1s&height=500`
                    "
                    style="border: none"
                    height="500"
                    width="600"
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowfullscreen="true"
                  ></iframe>
                </div> -->
                <div class="job-app">
                  <div class="video-section">
                    <div class="thumbnail">
                      <div v-show="recordingOn">
                        <video id="myVideo" playsinline class="video-js vjs-default-skin">
                          <p class="vjs-no-js">
                            To view this video please enable JavaScript, or consider upgrading to a web browser that
                            <a href="https://videojs.com/html5-video-support/" target="_blank"> supports HTML5 video. </a>
                          </p>
                        </video>
                        <br />
                        <button type="button" class="btn btn-info" @click.prevent="startRecording()"
                          v-bind:disabled="isStartRecording" id="btnStart">Start Recording</button>
                        <button type="button" class="btn btn-danger" @click.prevent="finishRecording()">Stop
                          Recording</button>
                        <button type="button" class="btn btn-success" @click.prevent="submitVideo()"
                          v-bind:disabled="isSaveDisabled" id="btnSave">
                          {{ submitText }}
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="retakeVideo()"
                          v-bind:disabled="isRetakeDisabled" id="btnRetake">Retake</button>
                      </div>
                      <iframe v-if="!recordingOn"
                        :src="`https://iframe.videodelivery.net/${selectedVideo.selectedVideo ? selectedVideo.selectedVideo.user_job_video_path : null}/thumbnails/thumbnail.jpg?time=1s&height=500`"
                        style="border: none" height="300" width="100%"
                        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                        allowfullscreen="true" id="stream-player2"></iframe>
                      <!-- <img src="https://img.youtube.com/vi/tE_lupFPrAg/0.jpg" /> -->
                    </div>
                    <div class="action-icons" v-if="!recordingOn && !playVideo">
                      <div @click="() => {
                        if (selectedVideo.selectedVideo != '') {
                          playVideo = true;
                        }
                      }
                        ">
                        <figure>
                          <img src="@/assets/app-icons/icon-play.svg" />
                        </figure>
                        <p>PLAY</p>
                      </div>
                      <div v-if="selectedVideo.selectedVideo != ''" @click="onDeleteVideo">
                        <figure>
                          <img src="@/assets/app-icons/icon-delete.svg" />
                        </figure>
                        <p>DELETE</p>
                      </div>
                      <div @click="getUploadUrl">
                        <figure>
                          <img src="@/assets/app-icons/icon-record.svg" />
                        </figure>
                        <p>RECORD</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!--End Video Section-->
              </div>
            </b-col>

            <b-col class="col-12" v-if="jobDetails.eiq && jobDetails.eiq.length > 0">
              <div class="flex-between border-bottom py-3">
                <div class="d-flex align-items-center">
                  <img src="@/assets/app-icons/cv.svg" class="img-fluid pr-3" alt="cv-icon" width="60px" />
                  <div>
                    <h5 class="fpn-b f-light">STEP 4</h5>
                    <h4 class="fpn-b mt-n2 mb-0">Emotional Intelligence Assessment</h4>
                  </div>
                </div>
              </div>
              <div class="py-3" v-if="jobDetails.eiq && jobDetails.eiq.length">
                <p class="f-light fs-14">The employer has asked all users to fill out the EI test, please take time to
                  answer this section carefully</p>
                <div v-for="(data, index) in jobDetails.eiq" :key="index" class="border-bottom py-3">
                  <h4 class="font-weight-bolder">{{ data.ei_type_name }}</h4>
                  <div v-for="(q, i) in data.ques" :key="i">
                    <p>Q{{ i + 1 }}. {{ q.eiq_name }}</p>
                    <b-form-group class="radio-square">
                      <b-form-radio-group :options="['Never', 'Rarely', 'Occasionally', 'Mostly', 'Always']"
                        v-model="ans.ei[index][i].answer"> </b-form-radio-group>
                    </b-form-group>
                    <div v-if="submit && String(ans.ei[index][i].answer).length == 0" class="error-msg">
                      <small>This field is required</small>
                    </div>
                  </div>

                  <!-- <div class="bg-white rounded-lg">
                    <b-textarea
                      v-if="data.question_type == 'Text Based'"
                      v-model="ans.eqTemplate[index].answer"
                      class="form-control"
                      width-50
                      w-100
                      rows="5"
                      max-rows="10"
                      placeholder="Enter Your Answer"
                      :state="
                        ans.eqTemplate[index].answer
                          ? ans.eqTemplate[index].answer.length <= 150
                          : null
                      "
                    ></b-textarea>
                    <div
                      v-if="
                        submit &&
                          String(ans.eqTemplate[index].answer).length == 0
                      "
                      class="error-msg"
                    >
                      <small>This field is required</small>
                    </div>
                  </div> -->
                </div>
              </div>
            </b-col>
            <b-col class="col-12" v-if="videoCanBeUploaded">
              <div class="flex-between border-bottom pb-2 align-items-center"
                v-if="Object.keys(virtualVideoInterview).length > 0 && virtualVideoInterview.constructor === Object && virtualVideoInterview.question != ''">
                <div class="d-flex align-items-center">
                  <img src="@/assets/app-icons/video-online.svg" class="img-fluid pr-3" alt="cv-icon" width="70px" />
                  <div>
                    <h5 class="fpn-b f-light">STEP 5</h5>
                    <h4 class="fpn-b mt-n2 mb-0">Choose Video Profile</h4>
                  </div>
                </div>
                <ul class="profile-slider user-slides slides-arrow d-flex mb-0">
                  <li class="prev"><i class="fa fa-angle-left"></i></li>
                  <li class="next"><i class="fa fa-angle-right"></i></li>
                </ul>
              </div>
              <div class="py-3 video-slider"
                v-if="Object.keys(virtualVideoInterview).length > 0 && virtualVideoInterview.constructor === Object && virtualVideoInterview.question != ''">
                <slick :options="videoSlider" v-if="videos.length > 0">
                  <div v-for="(data, index) in videos" :key="index">
                    <div class="py-3 px-4 position-relative choose-video-profile">
                      <a style="cursor: pointer" @click="selectVideo(data)">
                        <img
                          :src="`https://videodelivery.net/${data.user_job_video_path}/thumbnails/thumbnail.jpg?time=1s&height=300`"
                          style="border: none" height="300" width="100%" />
                      </a>

                      <img src="@/assets/app-icons/orange-video.svg" class="video-play-img" width="50px" />
                      <h5>{{ data.user_job_video_title }}</h5>
                    </div>
                  </div>
                </slick>
              </div>
            </b-col>
            <b-col class="col-12">
              <!-- <p class="text-center px-4 pt-3" v-if="videoCanBeUploaded">
                Your allowed only {{ videoCanBeUploaded }} profiles in your
                <br />current plan, upgrade to create more
              </p> -->
              <div class="d-flex border-top justify-content-center py-3 w-100">
                <div class="mx-3"></div>
                <button class="btn-success btn btn-custom px-4 py-2" @click="validateInput">SUBMIT APPLICATION</button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="share-modal" ref="modal" @ok="handleOk" hide-footer>
      <div>
        <h4>Share this job on social network</h4>
        <div class="social-icons-grid">
          <ShareNetwork network="Whatsapp" :url="`${SERVERURL}job-details/${jobDetails.job_id}`"
            :title="jobDetails.job_title ? jobDetails.job_title : ''"
            :description="jobDetails.job_description ? jobDetails.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-whatsapp"></i>
            </div>
          </ShareNetwork>
          <ShareNetwork network="Facebook" :url="`${SERVERURL}job-details/${jobDetails.job_id}`"
            :title="jobDetails.job_title ? jobDetails.job_title : ''"
            :description="jobDetails.job_description ? jobDetails.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-facebook"></i>
            </div>
          </ShareNetwork>
          <ShareNetwork network="Twitter" :url="`${SERVERURL}job-details/${jobDetails.job_id}`"
            :title="jobDetails.job_title ? jobDetails.job_title : ''"
            :description="jobDetails.job_description ? jobDetails.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-twitter"></i>
            </div>
          </ShareNetwork>
          <ShareNetwork network="Linkedin" :url="`${SERVERURL}job-details/${jobDetails.job_id}`"
            :title="jobDetails.job_title ? jobDetails.job_title : ''"
            :description="jobDetails.job_description ? jobDetails.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-linkedin"></i>
            </div>
          </ShareNetwork>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApplicantJobServices from "@/apiServices/ApplicantJobServices";
import ApplicantProfileService from "@/apiServices/ApplicantProfileService";
import CloudflareVideoPlayer from "vue-cloudflare-video-player";
import ApplyJobService from "@/apiServices/ApplyJobServices";
import GetVideoUploadUrl from "@/apiServices/GetVideoUploadUrl";
import moment from "moment";
import axios from "axios";
import { SERVERURL } from "@/config";
import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import videojs from "video.js";
import "webrtc-adapter";
import RecordRTC from "recordrtc";
import Record from "videojs-record/dist/videojs.record.js";
import FFmpegjsEngine from "videojs-record/dist/plugins/videojs.record.ffmpegjs.js";
import numeral from 'numeral'
export default {
  components: { CloudflareVideoPlayer },
  data() {
    return {
      SERVERURL: SERVERURL,
      player: "",
      retake: 0,
      isSaveDisabled: true,
      isStartRecording: false,
      unlockQues: false,
      mandatoryQuesLength: 0,
      selectedQuesId: null,
      selectedOpt: [],
      isRetakeDisabled: true,
      submitText: "Submit",
      videoUploaded: 0,
      videoCanBeUploaded: null,
      options: {
        controls: true,
        bigPlayButton: false,
        controlBar: {
          deviceButton: false,
          recordToggle: false,
          pipToggle: false,
        },
        width: 500,
        height: 300,
        fluid: true,
        plugins: {
          record: {
            pip: false,
            audio: true,
            video: true,
            maxLength: 900,
            debug: true,
          },
        },
      },
      ///////////////////////////////////////////
      recordingOn: false,
      playVideo: false,
      url: null,
      videoUrl: null,
      cv: {},
      cvName: "",
      submit: false,
      jobDetails: { bookmark: false },
      days: null,
      virtualInterview: [],
      mandatoryVI: [],
      videos: [],
      subs: null,
      selectedVideo: { question_id: null, selectedVideo: "" },
      virtualVideoInterview: {},
      fileSelected: false,
      ans: {
        virtualInterview: [],
        virtualVideoInterview: {},
        eqTemplate: [],
        ei: [],
      },
      eqAns: [],
      eqTempQues: [],
      videoSlider: {
        arrows: true,
        infinite: true,
        dots: false,
        slidesToScroll: 1,
        slidesToShow: 2,
        prevArrow: ".prev",
        nextArrow: ".next",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
        ],
      },
    };
  },
  beforeMount() {
    this.getJobApplication();
    this.getJobCv();
    this.getSubs();

    //this.getProfile();
  },
  mounted() { },
  computed: {
    ResumeName() {
      this.cvName = this.cv?.user_job_cv_path ? this.cv.user_job_cv_path.split("/")[5].split("_")[1] : "";
      return this.cvName;
    },
  },
  watch: {
    unlockQues: function (val) {
      console.log("val :>> ", val);
      // this.mandatoryVI = [];

      this.virtualInterview.map((vq) => {
        var index = this.mandatoryVI.findIndex((x) => x.question_id == vq.question_id);
        // here you can check specific property for an object whether it exist in your array or not
        if (index === -1) {
          this.mandatoryVI.push(vq);
          this.ans.virtualInterview.push({
            question_id: vq.question_id,
            answer: "",
          });
        } else {
          console.log(`vq.question_id`, vq.question_id);
          console.log(`already exists`);
        }
      });
    },
  },
  methods: {
    formattedNumber(sal) {
      //console.log(sal);

      return numeral(sal).format('0,0');
    },
    getProfile() {
      let applicant_id = this.$cookies.get("user_id");
      ApplicantProfileService.getApplicantProfile({ applicant_id }).then((res) => {
        this.cv = res.data.data.user_cv[0];
        console.log("this.cv :>> ", this.cv);
      });
    },
    getPrefilledQA() {
      const job_id = this.$route.params.job_id;
      ApplyJobService.GetPrefilledQA({ job_id }).then((res) => {

        const prefilled = res.data.data;
        if (prefilled.length > 0) {
          // console.log(
          //   `this.ans.virtualInterview:::::::::::`,
          //   this.ans.virtualInterview
          // );

          this.virtualInterview.map((x) => {
            prefilled.map((y) => {
              if (x.universal_question_id && x.universal_question_id == y.universal_question_id) {
                let [answered] = x.option.filter((f) => f.universal_question_option_id == y.universal_question_option_id);
                // console.log(`answered::::`, answered);
                if (answered) {
                  this.ans.virtualInterview.map((z) => {
                    if (z.question_id == x.question_id) {
                      z.answer = answered.value;
                    }
                  });
                }
              }
            });
          });

          this.ans.virtualInterview = this.ans.virtualInterview.map((m) => {
            const index = prefilled.findIndex((f) => f.question_id == m.question_id);

            const findOptionId = console.log("index :>> ", index);
            console.log("prefilled[index] :>> ", prefilled[index]);
            return {
              ...m,
              answer: prefilled[index].option_id,
            };
          });
        }
      });
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return;
      // }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("share-modal");
      });
    },
    daysAgo() {
      let now = moment();

      let jobCreated = moment(this.jobDetails.job_start_date).format("YYYY-MM-DD");
      console.log("this.jobDetails.job_start_date :===>> ", this.jobDetails.job_start_date);
      console.log("jobCreated :>> ", jobCreated);
      let diff = now.diff(jobCreated, "day");
      console.log("diff :>> ", diff);
      this.days = diff;
    },
    configPlayer() {
      if (this.player === "") {
        this.player = videojs("myVideo", this.options, () => {
          // print version information at startup
          var msg = "Using video.js " + videojs.VERSION + " with videojs-record " + videojs.getPluginVersion("record") + " and recordrtc " + RecordRTC.version;
          videojs.log(msg);
        });
        // error handling
        this.player.on("deviceReady", () => {
          this.player.record().start();
          console.log("device ready:");
        });
        this.player.on("deviceError", () => {
          console.log("device error:", this.player.deviceErrorCode);
        });
        this.player.on("error", (element, error) => {
          console.error(error);
        });
        // user clicked the record button and started recording
        this.player.on("startRecord", () => {
          console.log("started recording!");
        });
        // user completed recording and stream is available
        this.player.on("finishRecord", () => {
          this.isSaveDisabled = false;
          if (this.retake == 0) {
            this.isRetakeDisabled = false;
          }
          // the blob object contains the recorded data that
          // can be downloaded by the user, stored on server etc.
          console.log("finished recording: ", this.player.recordedData);
        });
      } else {
        console.log("player is not initialise", this.player);
      }
    },
    getJobBookmark() {
      const user_id = this.$cookies.get("user_id");
      const job_id = this.$route.params.job_id;
      ApplicantJobServices.GetSingleBookmark({ user_id, job_id }).then((res) => {
        console.log("res------------ :>> ", res.data.data);
        if (res.data.data && res.data.data.bookmark_active == "Y") {
          this.jobDetails.bookmark = true;
        } else {
          this.jobDetails.bookmark = false;
        }
        this.$forceUpdate();
      });
    },
    onChangeVIOption(q, o) {
      let option = { ...o, question_id: q.question_id };
      console.log("option :>> ", option);
      console.log(`selectedOpt`, this.selectedOpt);
      let quesPresent = this.selectedOpt.findIndex((f) => f.question_id == option.question_id);
      console.log("quesPresent :>> ", quesPresent);
      if (quesPresent >= 0) {
        this.selectedOpt[quesPresent] = option;
      } else {
        this.selectedOpt.push(option);
      }

      console.log("this.selectedOpt :>> ", this.selectedOpt);
      if (this.selectedOpt.length == this.mandatoryQuesLength) {
        let correctAns = this.selectedOpt.map((m) => m.correct);
        console.log("correctAns :>> ", correctAns);
        // console.log('correct.includes("N") :>> ', correctAns.includes("N"));
        if (!correctAns.includes("N")) {
          this.unlockQues = true;
        }
      }
      // this.selectedOpt = Array.from(
      //   new Set(this.selectedOpt.map((a) => a.question_id))
      // ).map((id) => {
      //   return this.selectedOpt.find((a) => a.question_id === id);
      // });

      // console.log("q :>> ", q);
      // console.log("o :>> ", o);
      // console.log(
      //   "this.selectedQuesId != q.question_id :>> ",
      //   this.selectedQuesId != q.question_id
      // );
      // if (o.correct == "Y") {
      //   this.mandatoryQuesLength--;
      // }
      // if (o.correct == "N") {
      //   this.mandatoryQuesLength++;
      // }
      // if (this.mandatoryQuesLength == 0) {
      //   this.unlockQues = true;
      // }
      // console.log("this.mandatoryQuesLength :>> ", this.mandatoryQuesLength);
      this.selectedQuesId = q.question_id;
    },
    async getJobApplication() {
      const job_id = this.$route.params.job_id;
      ApplicantJobServices.GetJobApplication({ job_id }).then((job) => {
        console.log("job.data.data :>> ", job.data.data);
        this.jobDetails = job.data.data[0];
        this.daysAgo();
        console.log("this.jobDetails ----====:>> ", this.jobDetails);
        // this.jobDetails.bookmark = false;
        this.getJobBookmark();

        // this.getEQTemplateQue();
        if (this.jobDetails.eiq && this.jobDetails.eiq.length) {
          this.jobDetails.eiq.forEach((e, i) => {
            this.ans.ei.push(e.ques);
            // this.ans.ei = [].concat.apply([], this.ans.ei);
          });
        }
        console.log("job.data.data :::::::::>> ", this.jobDetails.question);
        this.jobDetails.question.forEach((q, i) => {
          console.log("q :>> ", q);
          if (q.option && q.option.length > 0) {
            this.jobDetails.question[i].option = this.jobDetails.question[i].option.map((o) => {
              return {
                text: o.question_option_title,
                value: o.question_option_id,
                correct: o.question_option_correct,
                universal_question_option_id: o.universal_question_option_id,
              };
            });
          }
          if (q.wfc_id == 2) {
            this.virtualInterview.push(this.jobDetails.question[i]);
            this.virtualInterview = this.virtualInterview.map((v) => {
              return { ...v, error: false };
            });
            this.mandatoryVI = this.virtualInterview.filter((v) => v.question_is_mandatory == "Y");
            this.mandatoryQuesLength = this.mandatoryVI.length;
            this.ans.virtualInterview = this.mandatoryVI.map((m) => {
              return {
                universal_question_id: m.universal_question_id,
                question_id: m.question_id,
                answer: "",
              };
            });
          }
          if (q.wfc_id == 3) {
            this.virtualVideoInterview = this.jobDetails.question[i];
          }
        });
        this.getPrefilledQA();
        if (Object.keys(this.virtualVideoInterview).length != 0) {
          this.getApplicantVideos();
        }
        // console.log("this.jobDetails :>> ", this.jobDetails);
      });
    },
    getSubs() {
      const user_id = this.$cookies.get("user_id");
      ApplicantProfileService.getUserSubscription(user_id).then((res) => {
        console.log("res.data ====:>> ", res.data.data);
        this.subs = res.data.data.length ? res.data.data[0] : null;
      });
    },
    async getUploadUrl() {
      //  this.showVideoRecorder();
      // if (
      //   !this.subs ||
      //   (this.subs.subs_pkg_no_of_videos &&
      //     this.subs.subs_pkg_no_of_videos == 0)
      // ) {
      //   this.$toasted.show("You dont have plan", {
      //     duration: 6000,
      //     icon: "clear",
      //   });
      //   return;
      // }

      GetVideoUploadUrl.GetVideoUploadUrl()
        .then((res) => {
          console.log("get upload url successfully!");
          this.url = res.data.data.result.uploadURL;
          //  this.dropzoneOptions.url = this.url;
          this.recordingOn = true;
          this.configPlayer();

          //   this.onUpload();
        })
        .catch((err) => {

          this.recordingOn = false;
          this.$toasted.show(
            `Internal Server Error.
            Video May not upload.
            please Try Again`,
            {
              duration: 5000,
              icon: "clear",
            }
          );
        });
    },

    onDeleteVideo() {
      if (this.videos.length > 0 && this.selectedVideo) {
        const video_id = this.selectedVideo.selectedVideo.user_job_video_id;
        console.log("video_id :>> ", video_id);
        this.$bvModal
          .msgBoxConfirm("Do You Want to delete this video", {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              ApplicantProfileService.deleteVideo({ video_id }).then((res) => {
                this.$toasted.show("Video Delete Successfully!", {
                  duration: 6000,
                  icon: "check",
                });
                this.getApplicantVideos();
              });
            } else {
              return;
            }
          });
      } else {
        this.$toasted.show("Select Video", { duration: 6000, icon: "clear" });
      }
    },

    share() {
      this.$bvModal.show("share-modal");
    },
    onDeleteResume() {
      if (this.cv && !this.cv.parser_id) {
        const cv_id = this.cv.user_job_cv_id;

        ApplicantProfileService.DeleteJobCv(cv_id)
          .then((res) => {

            this.$toasted.show("Job Cv Deleted", {
              duration: 6000,
              icon: "check",
            });
            // this.cv = res.data.data[0];
            this.cv = null;
          })
          .catch((err) => {

            this.$toasted.show("Job Cv not deleted", {
              duration: 6000,
              icon: "clear",
            });
          });
      } else {
        this.cv = null;
      }
    },
    AddBookmark(job_id) {
      const user_id = this.$cookies.get("user_id");
      ApplicantJobServices.AddBookmark({ user_id, job_id })
        .then((res) => {
          if (res.data.data && res.data.data.bookmark_active == "Y") {
            this.jobDetails.bookmark = true;
          } else {
            this.jobDetails.bookmark = false;
          }
          this.$forceUpdate();
        })
        .catch((err) => {

          // this.jobDetails.bookmark = false;
          // this.$forceUpdate();
          // this.getSingleBookmark(user_id, job_id);
        });
    },

    getJobCv() {
      const payload = {
        user_id: this.$cookies.get("user_id"),
        job_id: this.$route.params.job_id,
      };
      ApplicantProfileService.GetJobCv(payload).then((res) => {
        this.cv = res.data.data[0];
      });
    },
    onFileChange(e) {
      let file = e.target.files[0];
      console.log("file :>> ", file);
      if (file.type != "application/pdf") {
        this.$toasted.show("Resume should be in pdf format", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }

      if (file.size < 1000000) {
        let job_id = this.$route.params.job_id;
        this.fileSelected = true;
        let formData = new FormData();
        const user_id = +this.$cookies.get("user_id");
        formData.append("resume", file);
        formData.append("job_id", job_id);
        ApplicantProfileService.uploadNewCv({ formData, user_id }).then((res) => {
          if (res.data.data.status) {
            this.cv = res.data.data.cv;
          } else {
            this.cv = res.data.data.cv;
          }
        });
      } else {
        this.$toasted.show("Upload resume less than 1mb", {
          duration: 6000,
          icon: "clear",
        });
      }
    },
    validateVirtualQ() {
      let valid = true;
      return new Promise((resolve, reject) => {
        this.ans.virtualInterview.map((m) => {
          if (m.answer == "") {
            valid = false;
          }
        });
        resolve(valid);
      });
    },
    validateEQ() {
      let valid = true;
      return new Promise((resolve, reject) => {
        this.ans.ei.map((m, i) => {
          if (m[i].answer == "") {
            valid = false;
          }
        });
        resolve(valid);
      });
    },
    async validateInput() {
      this.submit = true;
      const viAnss = await this.validateVirtualQ();
      const eqAnss = await this.validateEQ();
      const virtualVideoAnss =
        Object.keys(this.virtualVideoInterview).length == 0 || (Object.keys(this.virtualVideoInterview).length > 0 && this.virtualVideoInterview.constructor === Object && Object.keys(this.ans.virtualVideoInterview).length > 0 && this.ans.virtualVideoInterview.constructor === Object) ? true : false;
      console.log("vi :>> ", viAnss);
      console.log(`virtualVideoAnss`, virtualVideoAnss);

      if (viAnss && eqAnss && virtualVideoAnss) {
        this.submit = false;
        // let jque = this.jobDetails.question;
        // let eq = this.eqTempQues;
        this.ans.ei = [].concat.apply([], this.ans.ei);

        console.log("this.ans.ei :>> ", this.ans.ei);

        this.onSubmit();
      } else {
        this.$toasted.show("There are some fields missing or you are not eligible to apply for this job", {
          duration: 6000,
          icon: "clear",
        });
      }

      // const textAns = await this.$refs.Input.refs.textans.validate();
      // const mcqAns = await this.$refs.Input.refs.mcqans.validate();
      // //const eqTextAns = await this.$refs.Input.refs.eqTextAns.validate();
      // console.log("textAns, mcqAns :>> ", textAns.valid, mcqAns.valid);

      //this.onSubmit();
    },
    getEQTemplateQue() {
      this.jobDetails.question.forEach((q) => {
        if (q.wfc_id == 4) {
          console.log("q in------- :>> ", q);
          this.eqTempQues.push(q);
          this.eqTempQues = this.eqTempQues.map((v) => {
            return { ...v, error: false };
          });
          this.ans.eqTemplate = this.eqTempQues.map((eq) => {
            return {
              question_id: eq.question_id,
              answer: "",
            };
          });
          this.eqTempQues.forEach((q, i) => {
            console.log("q.options :::::::::::::===:>> ", q);
          });
        }
      });
    },
    selectVideo(video_id) {
      console.log("video_id :>> ", video_id);
      video_id = typeof video_id == "string" ? { user_job_video_path: video_id } : video_id;
      this.selectedVideo.selectedVideo = video_id;
      this.selectedVideo.question_id = this.virtualVideoInterview.question_id;
      this.ans.virtualVideoInterview = {
        question_id: this.selectedVideo.question_id,
        video_id: video_id,
      };
      this.recordingOn = false;
      this.playVideo = false;
      console.log("this.selectedVideo :>> ", this.selectedVideo);
      this.$toasted.show("Video selected!", { duration: 6000, icon: "check" });

      const player = Stream(document.getElementById("stream-player2"));
      console.log(`player`, player);
      player.addEventListener("pause", () => {
        this.recordingOn = false;
        this.playVideo = false;
      });
      player.addEventListener("play", () => {
        this.playVideo = true;
        this.recordingOn = false;
      });
      player.play().catch(() => {
        console.log("playback failed, muting to try again");
        player.muted = true;
        player.play();
      });
    },
    getApplicantVideos() {
      const user_id = this.$cookies.get("user_id");
      ApplicantProfileService.GetUploadedVideos({ user_id }).then((video) => {
        this.videos = video.data.data;
        // this.videos = video.data.data.map((m) => {
        //   return {
        //     video_id: m.user_job_video_path,
        //     video_title: m.user_job_video_title,
        //   };
        // });
        console.log("this.videos :>> ", this.videos);
        if (this.subs) {
          this.videoCanBeUploaded = +this.subs.subs_pkg_no_of_videos - this.videos.length;
        }
        this.selectedVideo.question_id = this.virtualVideoInterview.question_id;
        // this.selectedVideo.selectedVideo =
        //   this.videos.length > 0 ? this.videos[0].video_id : null;
      });
    },
    onClickUrl() {
      if (this.cv && this.cv?.user_job_cv_path) {
        window.open(this.cv.user_job_cv_path, "_blank");
      }
    },
    OnSubmitJobVideo() {
      const videoPath = {
        user_job_video_path: this.videoUrl.split("/")[3].split("-")[0],
      };
      // const job_id = this.$route.params.job_id;
      // const company_id = this.jobDetails.company_id;
      console.log("videoPath :>> ", videoPath);
      this.selectVideo(videoPath);
      this.recordingOn = false;
      this.player.record().stopDevice();
      this.player.record().reset();
      this.submitText = "Submit";
      this.isSaveDisabled = true;
    },
    onSubmit() {
      console.log("this.ans :>> ", this.ans);
      let loader = this.$loading.show();
      if (!this.cv) {
        this.$toasted.show("Upload your resume", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }

      const ans = this.ans;
      const user_id = this.$cookies.get("user_id");
      const company_id = this.jobDetails.company_id;
      const job_id = this.jobDetails.job_id;
      const video_path = this.selectedVideo.selectedVideo.user_job_video_path;

      ApplicantJobServices.AddJobAnswer({
        ans,
        user_id,
        job_id,
        company_id,
        video_path,
      })
        .then((res) => {

          this.$toasted.show("Your application was successful!", {
            duration: 6000,
            icon: "check",
          });
          loader.hide();
          this.$router.push({
            name: "Job-Applied-Success",
            params: { job_id: this.$route.params.job_id },
          });
        })
        .catch((err) => {

          loader.hide();
          this.$toasted.show("Your application was not successful!", {
            duration: 6000,
            icon: "clear",
          });
        });
    },

    /////////////////////VideoJs config////////////////////////
    startRecording() {
      this.isStartRecording = true;
      this.player.record().getDevice();
    },
    finishRecording() {
      //  this.player.record().stop();
      this.player.record().stopDevice();
      //this.player.record().remainingTime();
      this.isRetakeDisabled = false;
    },
    submitVideo() {
      this.isSaveDisabled = true;
      this.isRetakeDisabled = true;
      var data = this.player.recordedData;
      var formData = new FormData();
      formData.append("file", data, data.name);
      this.submitText = "Uploading " + data.name;
      console.log("uploading recording:", data.name);
      this.player.record().stopDevice();
      this.$bvModal.show("myModel");
      axios
        .post(this.url, formData, {
          onUploadProgress: function (progressEvent) {
            this.videoUploaded = parseInt(Math.round(progressEvent.loaded / progressEvent.total) * 100);
          }.bind(this),
        })
        .then((uploadResult) => {
          console.log("uploadResult :>> ", uploadResult);
          this.videoUrl = uploadResult.config.url;
          this.OnSubmitJobVideo();
          //  this.onSubmit();
          //this.player.record().stopDevice();

          this.isStartRecording = false;
          this.submitText = "Submit";
          this.isSaveDisabled = true;
        })
        .catch((error) => {

          this.videoUploaded = 0;
          this.player.record().stopDevice();
          this.isStartRecording = false;
          this.submitText = "Submit";
          this.isSaveDisabled = true;
        });
      // fetch(this.uploadUrl, {
      //   method: "POST",
      //   body: formData,
      //   headers: {
      //     "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
      //   },
      // })
      //   .then((success) => {
      //     console.log("recording upload complete.");
      //     this.submitText = "Upload Complete";
      //   })
      //   .catch((error) => {
      //     console.error("an upload error occurred!");
      //     this.submitText = "Upload Failed";
      //   });
    },
    retakeVideo() {
      this.isSaveDisabled = true;
      this.isRetakeDisabled = true;
      this.retake += 1;

      this.player.record().start();
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style>
#bg-splash {
  position: relative;
  width: 100%;
  background-image: url("../../assets/user-header.jpg");
  background-size: cover;
}

.video-img {
  height: 300px;
  width: 500px;
  object-fit: cover;
}

.job-application-card .job-details-footer .left {
  flex: 0 0 40% !important;
  grid-template-columns: auto auto auto;
  cursor: pointer;
}

.video-section {
  position: relative;
}

.job-app .video-section {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.choose-video-profile .video-play-img {
  top: 47%;
}

.slides-arrow li {
  list-style-type: none;
}

.slides-arrow li .fa {
  font-size: 30px;
  color: black;
  cursor: pointer;
  padding-right: 30px;
}
</style>
